import React from 'react'
import { Fade } from 'react-reveal';
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const Contactus = props => (
    <Layout>
        <Helmet>
            <title>Contact Us</title>
            <meta name="description" content="Contact Us" />
        </Helmet>
    <Fade duration={2500}>
      <div id="main" className="wrapper style1">
        <div className="container">
          <header className="major">
            <h2>Contact Us</h2>
            <p>Always open to fresh ideas &amp; opportunities!</p>
          </header>
          <section>
            <p style={{whiteSpace:`pre-line`, textAlign:`center`}}>SGP Foods Pte. Ltd.</p>
            <p style={{whiteSpace:`pre-line`, textAlign:`center`}}>9 Raffles Place, B1-01, Singapore 048619</p>
            <p style={{whiteSpace:`pre-line`, textAlign:`center`}}>+65 8831 6024 (WhatsApp)</p>
            <p style={{whiteSpace:`pre-line`, textAlign:`center`}}>ask@sgpfoods.com</p>
          </section>
        </div>
      </div>
    </Fade>
  </Layout>
)
export default Contactus
